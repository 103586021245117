/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import {
  Menus,
  Url,
} from "../../../../../app/pages/dashboard/DashboardWrapper";
import Authorize from "../../../../../app/modules/auth/components/Authorize";

type SetMenusType = Dispatch<SetStateAction<Menus[] | undefined>>;

// Define the type for Api object
interface ApiType {
  setMenus?: SetMenusType;
}

export const Api: ApiType = {};

const assignSetMenus = (setCategory: SetMenusType) => {
  Api.setMenus = setCategory;
};

const SidebarMenuMain = () => {
  const intl = useIntl();
  const menus = sessionStorage.getItem("menus");
  const allMenus = menus ? JSON.parse(menus) : null;
  const sideMenus: Menus[] = allMenus?.menus;

  // const [menus, setMenus] = useState<Menus[] | undefined>([]);

  // assignSetMenus(setMenus);

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="dashboard"
        title="Dashboard"
        // fontIcon="bi bi-faChevronCircleUp"
      />
      {sideMenus?.map((cat, index) => {
        return cat?.menus?.length > 1 ? (
          <Authorize
            hasAnyPermission={
              cat?.menus?.map((menu) => `${menu.menuCode}|VIEW`) || []
            }
          >
            <SidebarMenuItemWithSub
              to="#"
              icon={cat?.catIconLink}
              title={cat?.menuCategory}
            >
              {cat.menus.map(
                (menu, index) =>
                  menu.menuName !== "Currency Conversion" && (
                    <Authorize
                      hasAnyPermission={[`${menu?.menuCode}|VIEW`]}
                      key={index}
                    >
                      <SidebarMenuItem
                        to={Url[menu?.menuCode]?.path || "#"}
                        title={menu.menuName}
                        hasBullet={true}
                      />
                    </Authorize>
                  )
              )}
            </SidebarMenuItemWithSub>
          </Authorize>
        ) : (
          <Authorize hasAnyPermission={[`${cat?.menus[0]?.menuCode}|VIEW`]}>
            <SidebarMenuItem
              to={Url[cat?.menus[0]?.menuCode]?.path || "#"}
              icon={cat?.catIconLink}
              title={`${cat?.menus[0]?.menuName}`}
              fontIcon="bi-layers"
            />
          </Authorize>
        );
      })}

      <SidebarMenuItemWithSub
        to="#"
        icon="https://xfin-doc.s3.ap-south-1.amazonaws.com/document/icon/statements.svg"
        title="Reconcilation"
      >
        <SidebarMenuItem
          to="/reconcilation"
          title="Reconcilation"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/recon-1"
          title="Reconcilation-1"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  );
};

export { SidebarMenuMain };
